export const SCREEN_HOME = 'Dashboard'
export const SCREEN_HOME_ADMIN = 'Admin Dashboard'
export const SCREEN_LOGIN = 'Auth Login'
export const SCREEN_LOGIN_ADMIN = 'Auth Login'
export const SCREEN_FORGOT_PASSWORD = 'Auth Forgot Password'
export const RESET_MAIL_COMPLETE = 'Auth Forgot Password Complete'
export const RESET_PASSWORD = 'Auth Reset Password'
export const RESET_PASSWORD_COMPLETE = 'Reset Password Complete'
export const SCREEN_FORGOT_PASSWORD_ADMIN = 'Auth Forgot Password'
export const RESET_MAIL_COMPLETE_ADMIN = 'Auth Forgot Password Complete'
export const RESET_PASSWORD_ADMIN = 'Auth Reset Password'
export const RESET_PASSWORD_COMPLETE_ADMIN = 'Reset Password Complete'
export const SCREEN_404 = '404 page'
export const REGISTER_MEMBER = 'Register member'
export const REGISTER_MEMBER_COMPLETE = 'Sent mail register member complete'
export const REGISTER_INFO_MEMBER = 'Register info member'
export const REGISTER_INFO_MEMBER_COMPETE = 'Register info member complete'
export const REGISTRATION_VISIT_DESTINATION = 'Registration Visit Destination'
export const REGISTRATION_VISIT_COMPLETE = 'Registration Visit Result'
export const REGISTRATION_VISIT_DESTINATION_NOW =
  'Registration Visit Destination Now'
export const SYSTEM_ERROR = 'system error'
export const ROUTER_AUTH = {
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  forgotPasswordComplete: '/auth/reset-password/mail/complete',
  resetPassword: '/auth/reset-password',
  resetPasswordComplete: '/auth/reset-password/complete',
  sendMail: '/auth/send-mail',
  sendMailComplete: '/auth/sent-mail-register-member/complete',
  registerInfoMemberComplete: '/auth/register-info-member/complete'
}

export const ROUTER_WEB = {
  dashboard: '/dashboard',
  home: '/',
  workApplication: '/work-application',
  otherApplications: '/other-applications',
  tenantManagement: '/tenant-management',
  partnerManagement: '/partner-management',
  constructionManagement: '/construction-management',
  ownUserManagement: '/own-user-management',
  mailDelivery: '/mail-delivery',
  cms: '/cms',
  formDownload: '/form-download',
  pageNotFound: '/404',
  systemError: '/system-error',
  components: '/components',
  workApplicationCreate: '/work-application/create',
  currentSurveyCreate: '/other-applications/create-current-survey-request',
  interviewShottingCreate:
    '/other-applications/create-interview-shooting-request',
  buildingPhotoCreate: '/other-applications/create-building-photo-request',
  advertisement: '/other-applications/create-advertisement-request',
  requestCreate: '/request/create',
  requestDetail: '/request/:requestId'
}

export const ROUTER_ADMIN = {
  dashboard: '/admin/dashboard',
  home: '/',
  pageNotFound: '/404',
  systemError: '/system-error',
  components: '/components',
  currentSurveyCreate:
    '/admin/other-applications/create-current-survey-request',
  interviewShottingCreate:
    '/admin/other-applications/create-interview-shooting-request',
  buildingPhotoCreate:
    '/admin/other-applications/create-building-photo-request',
  advertisement: '/admin/other-applications/create-advertisement-request',
  requestCreate: '/request/create',
  otherApplications: '/admin/other-applications',
  workApplication: '/admin/work-application',
  workApplicationCreate: '/admin/work-application/create',
  requestDetail: '/request/:requestId',
  account: '/admin/own-user-management',
  accountDetail: '/admin/own-user-management/edit/:accountId(\\d+)',
  accountCreate: '/admin/own-user-management/create',
  general: '/admin/general-management',
  news: '/admin/news-management',
  generalCreate: '/admin/general-management/create',
  newsCreate: '/admin/news-management/create',
  faq: '/admin/faq-management',
  faqCreate: '/admin/faq-management/create',
  dashboard2: '/dashboard',
  listScheduledAdmissions: '/list-reception',
  waitingApprovalList: '/waiting-approval-list'
}
