import axios, { AxiosInstance } from 'axios'

import router from '@/router'
// import { authLogout } from '@/services/auth.service'
import { getAccessToken, removeToken } from '@/utils/token'

class Http {
  instance: AxiosInstance

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods':
          'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
      }
    })

    this.instance.interceptors.request.use(
      (config) => {
        const token = getAccessToken()
        if (token) {
          config.headers['Accept'] = 'application/json'
          config.headers['Authorization'] = `Bearer ${token}`
        }

        return config
      },
      (error) => Promise.reject(error)
    )

    this.instance.interceptors.response.use(
      (response) => {
        return response.data
      },
      async (error) => {
        if (
          error.response?.status === 401 &&
          error?.config?.url !== '/login' &&
          error?.config?.url !== '/password/forgot'
        ) {
          removeToken()
          return router.push('/auth/login')
        }
        if (error.response?.status === 403) {
          return router.push('/403')
        }
        if (error.response?.status === 500 || error?.code === 'ERR_NETWORK') {
          return router.push('/system-error')
        }
        return Promise.reject(error)
      }
    )
  }
}

const http = new Http().instance

export default http
