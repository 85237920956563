export const ACCESS_TOKEN = 'access_token'
export const ACTIVE_TIME = 'active_time'
export const REFRESH_TOKEN = 'refresh_token'
export const USER_ME = 'me'
export const DEFAULT_PAGE = 1
export const DEFAULT_PER_PAGE = 20
export const TYPE_ADMIN = 1
export const TYPE_USER = 2
export const REQUEST_STATUS = {
  DRAFT: 1,
  IN_PROGRESS: 2,
  APPROVED: 3,
  REJECT: 4,
  CANCEL: 5
}

export const REQUEST_TYPE = {
  JOB: 1,
  SURVEY: 2,
  AD: 3,
  BUILDING_PHOTO: 4,
  INTERVIEW: 5,
  RESOURCE: 6,
  FIRE_EQUIPMENT: 7,
  SIGN: 8,
  AIR_CONDITION: 9,
  EVENT: 10,
  WORKDAY: 11,
  ASSIGN_SECURITY_CARD: 12,
  CHANGE_SETTING_SECURITY_CARD: 13,
  REMOVE_SECURITY_CARD: 14,
  DISABLE_SECURITY_CARD: 15,
  REOPEN_SECURITY_CARD: 16,
  CHANGE_SETTING_SECURITY_CARD_READER: 17,
  OPEN_SECURITY_CARD_LOG_DATA: 18,
  OPEN_SECURITY_CARD_READER: 19,
  ARRANGEMENT: 20,
  PARKING_TICKET: 21,
  SAFE_PARKING: 22,
  IMPORTANT_NOTIFICATION: 23
}

export const ROUTER_REQUEST = {
  JOB: '/work-application',
  SURVEY: '/other-applications/current-survey-request',
  AD: '/other-applications/advertisement-request',
  BUILDING_PHOTO: '/other-applications/building-photo-request',
  INTERVIEW: '/other-applications/interview-shooting-request',
  RESOURCE: '/other-applications/building-staff-survey',
  FIRE_EQUIPMENT: '/other-applications/fire-equipment-request',
  SIGN: '/other-applications/area-sign-request',
  AIR_CONDITION: '/other-applications/air-condition-request',
  EVENT: '/other-applications/event-approval',
  WORKDAY: '/other-applications/change-work-time',
  ASSIGN_SECURITY_CARD: '/other-applications/security-card-request',
  CHANGE_SETTING_SECURITY_CARD:
    '/other-applications/change-security-card-request',
  REMOVE_SECURITY_CARD: '/other-applications/remove-security-card-request',
  DISABLE_SECURITY_CARD: '/other-applications/disable-security-card',
  REOPEN_SECURITY_CARD: '/other-applications/open-security-card',
  CHANGE_SETTING_SECURITY_CARD_READER:
    '/other-applications/change-security-card-reader',
  OPEN_SECURITY_CARD_LOG_DATA:
    '/other-applications/security-card-log-data-request',
  OPEN_SECURITY_CARD_READER:
    '/other-applications/open-security-card-reader-log-data',
  ARRANGEMENT: '/other-applications/arrangement',
  PARKING_TICKET: '/other-applications/parking-ticket-request',
  SAFE_PARKING: '/other-applications/safe-parking',
  IMPORTANT_NOTIFICATION: '/other-applications/important-notification'
}

export const PERMISSION = {
  TEKKO_BUILDING: 1,
  TCS: 2,
  TENANTS_OFFICE: 3,
  TENANTS_STORE: 4,
  CONTRACTORS_ASSIGN: 5,
  CONTRACTORS_OTHER: 6,
  PARTNERS_SERCURITY: 7,
  PARTNERS_INFRASTRUCTURE: 8,
  PARTNERS_PARKING: 9,
  PARTNERS_TRANSPORT: 10,
  PARTNERS_RECEPTIONIST: 11,
  PARTNERS_SANITATION_WORKER: 12,
  PARTNERS_CLEANING: 13,
  PARTNERS_GARBAGE_DISPOSAL: 14,
  NTT: 15
}

export const COMPANY = {
  TEKKO_BUILDING: 1,
  TCS: 2,
  NTT: 3
}

export const TYPE_TENANT = {
  TENANTS_OFFICE: 2,
  TENANTS_STORE: 1,
  BOTH: 3
}

export const PERSON_TYPE = {
  ADMIN: 1,
  MEMBER: 2,
  S_MEMBER: 3
}

export const MENU_TYPE = {
  DASHBOARD: 1,
  WORK_APPLICATION: 2, //don't use
  OTHER_APPLICATION: 3, //don't use
  TENANT_MANAGEMENT: 4,
  PARTNER_MANAGEMENT: 5,
  CONTRACTOR_COMPANY_DESIGNATED: 6,
  OWN_USER_MANAGEMENT: 7,
  MAIL_DELIVERY: 8,
  CMS: 9,
  QA_FORM: 10,
  MONTHLY_BILLING: 11,
  DOWNLOAD_FORM_GENERAL: 12,
  DOWNLOAD_FORM_REQUEST: 13,
  FAQ: 14,
  REQUEST: 15,
  CONTRACTOR_COMPANY: 16,
  PARKING_LOT_HOLIDAY: 17,
  CHECKING_TIGHTENING_PROCESS: 24,
  APPROVAL_LIST: 18,
  ADMISSION_RECEPTION: 19,
  ADMISSION_INFORMATION_SEARCH: 20,
  CARD_INFORMATION_LIST: 21,
  CARD_LOGS: 22,
  CARD_STATUS_CHANGE: 23,
  AGGREGATION: 25,
  VISIT_RESERVATION: 26
}

export const USE_PARKING_OPTIONS = [
  {
    title: 'あり',
    value: 1
  },
  {
    title: 'なし',
    value: 2
  }
]

export const SIDEBAR_TYPE = {
  ELECTRONIC_SYSTEM: 0,
  ADMISSION_SYSTEM: 1
}
