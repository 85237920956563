import './registerServiceWorker'
import './assets/styles/app.scss'
import 'animate.css'
import 'v-calendar/style.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

import VueDragSelect from '@coleqiu/vue-drag-select'
import { createHead } from '@unhead/vue'
import { QuillEditor } from '@vueup/vue-quill'
import { createPinia } from 'pinia'
import { setupCalendar } from 'v-calendar'
import VCalendar from 'v-calendar'
import { createApp } from 'vue'
import { VueRecaptchaPlugin } from 'vue-recaptcha'

import { i18n } from '@/utils/i18n'

import App from './App.vue'
import router from './router'

const app = createApp(App)
app.component('QuillEditor', QuillEditor)
// app.component('VCalendar', Calendar)
// app.component('VDatePicker', DatePicker)
const pinia = createPinia()
const head = createHead()
// Use plugin with optional defaults
app.use(VCalendar, {})
app.use(head)
app.use(setupCalendar, {})
app.use(VueRecaptchaPlugin, {
  v2SiteKey: process.env.VUE_APP_GOOGLE_KEY
})
app.use(VueDragSelect)

app.use(i18n).use(pinia).use(router).mount('#app')
