import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { ref } from 'vue'

import { Toast, ToastList } from '@/types/toast.type'

export const useToast = defineStore('toast', () => {
  const notification = ref<ToastList[]>([])

  const addNotices = (payload: Toast) => {
    notification.value = [{ ...payload, id: uuidv4() }, ...notification.value]
  }

  const removeNotice = (id: string) => {
    notification.value = notification.value.filter((item) => item.id !== id)
  }

  return {
    notices: notification,
    addNotices,
    removeNotice
  }
})
