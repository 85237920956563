import { IFormRegisterVisit, IParamSearchCompany } from '@/types/auth.type'
import http from '@/utils/http'

interface IParamInfoMember {
  company_name?: string
  name?: string
  email?: string
  tel?: string
  token?: string
}

export const authLogin = async (
  email: string,
  password: string,
  user_type: number
) => {
  return await http.post('/login', { email, password, user_type })
}

export const sendMailForgotPassword = async (
  email: string,
  user_type: number
) => {
  return await http.post('/password/forgot', { email, user_type })
}

export const checkToken = async (token: string) => {
  return await http.post('/password/check-token', { token })
}

export const resetPassword = async (
  token: string,
  password: string,
  password_confirmation: string
) => {
  return await http.post('/password/reset', {
    token,
    password,
    password_confirmation
  })
}

export const authLogout = async () => {
  return await http.get('/logout')
}

export const getMe = async () => {
  return await http.get('/me')
}

export const registerMember = async (email: string) => {
  return await http.post('/contractors-others/pre-register', { email })
}

export const checkTokenRegisterMember = async (token: string) => {
  return await http.post('/contractors-others/check-token', { token })
}

export const registerInfoMember = async (param: IParamInfoMember) => {
  return await http.post('/contractors-others', param)
}

export const searchCompany = async (params: IParamSearchCompany) => {
  return await http.get('/reception-register/companies', { params })
}

export const getListFloorByCompany = async (companyId: number , params : any) => {
  return await http.get(`/reception-register/companies/${companyId}/floors`, { params })
}

export const registerReception = async (params: IFormRegisterVisit) => {
  return await http.post('/reception-register', { ...params })
}

export const getInfoReception = async (id: string) => {
  return await http.get(`/reception-register/${id}`)
}
