import { RouteRecordRaw } from 'vue-router'

import { SidebarType } from '@/types/home.type'

const getView = (path: string) => {
  return () => import(`@/views/${path}`)
}

export const routesAdmin: Array<RouteRecordRaw> = [
  // auth
  {
    path: '/auth',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'Auth Login',
        component: getView('auth/LoginView.vue'),
        meta: {
          title: 'ログイン'
        }
      },
      {
        path: 'forgot-password',
        name: 'Auth Forgot Password',
        component: getView('auth/ForgotPasswordView.vue'),
        meta: {
          title: 'パスワードリセット'
        }
      },
      {
        path: 'reset-password/mail/complete',
        name: 'Auth Forgot Password Complete',
        component: getView('auth/ForgotPasswordCompleteView.vue'),
        meta: {
          title: 'パスワードリセット'
        }
      },
      {
        path: 'reset-password',
        name: 'Auth Reset Password',
        component: getView('auth/ResetPasswordView.vue'),
        meta: {
          title: 'パスワード再設定'
        }
      },
      {
        path: 'reset-password/complete',
        name: 'Reset Password Complete',
        component: getView('auth/ResetPasswordCompleteView.vue'),
        meta: {
          title: 'パスワード再設定'
        }
      },
      {
        path: 'registration-visit-destination',
        name: 'Admin Registration Visit Destination',
        component: getView('auth/RegistrationVisitDestination.vue'),
        meta: {
          title: '入館受付情報登録（訪問先選択）'
        }
      },
      {
        path: 'registration-visit-now',
        name: 'Admin Registration Visit Destination Now',
        component: getView('auth/RegistrationVisitNow.vue'),
        meta: {
          title: 'visit now'
        }
      },
      {
        path: 'registration-visit-complete/:uuid',
        name: 'Registration Visit Result',
        component: getView('auth/RegisterVisitResult.vue'),
        meta: {
          title: '入館受付情報表示（テナント予約）英語'
        }
      }
    ]
  },

  // Admin router
  {
    path: '/admin',
    component: () => import('@/layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'Admin ',
        children: [
          {
            path: 'dashboard',
            name: 'Admin Dashboard',
            component: getView('home/DashboardView.vue'),
            meta: {
              auth: true,
              title: 'ダッシュボード',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'request-dispatcher',
        name: 'Admin Request',
        children: [
          {
            path: '',
            name: 'Admin Request Dispatcher',
            component: getView('RequestDispatcher/OverviewRequest.vue'),
            meta: {
              auth: true,
              title: '各種申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'search',
            name: 'Admin Request Dispatcher Search',
            component: getView('RequestDispatcher/SearchRequest.vue'),
            meta: {
              auth: true,
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'important-notification-search',
        name: 'Admin Important Notification SideBar',
        children: [
          {
            path: '',
            name: 'Admin Important Notification SideBar Search',
            component: getView('RequestDispatcher/SearchRequest.vue'),
            meta: {
              auth: true,
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'work-application',
        name: 'Admin Work application',
        children: [
          // {
          //   path: '',
          //   name: 'Admin Work application List',
          //   component: getView('WorkApplication/WorkApplicationList.vue'),
          //   meta: {
          //     auth: true,
          //     title: '作業申請',
          //     fullWidth: true,
          //     sidebar: SidebarType.HomePage
          //   }
          // },
          {
            path: 'create',
            name: 'Admin Work application Create',
            component: getView('Request/RequestCreateView.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'copy/:requestId(\\d+)',
            name: 'Admin Copy Request',
            component: getView('Request/RequestEditView.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':requestId(\\d+)',
            name: 'Admin Request Detail',
            component: getView('Request/RequestDetailView.vue'),
            meta: {
              auth: true,
              title: '作業申請-詳細画面',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'edit/:requestId(\\d+)',
            name: 'Admin Edit Detail',
            component: getView('Request/RequestEditView.vue'),
            meta: {
              auth: true,
              title: '作業申請',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'approve/:requestId(\\d+)',
            name: 'Admin Approve',
            component: getView('Request/RequestApproveView.vue'),
            meta: {
              auth: true,
              title: '作業申請-承認',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'other-applications',
        name: 'Admin Other applications',
        children: [
          // {
          //   path: '',
          //   name: 'Admin Other applications List',
          //   component: getView('OtherApplication/OtherApplicationList.vue'),
          //   meta: {
          //     auth: true,
          //     title: 'その他の申請',
          //     fullWidth: true,
          //     sidebar: SidebarType.HomePage
          //   }
          // },
          {
            path: 'current-survey-request',
            name: 'Admin Current Survey',
            children: [
              {
                path: '',
                name: 'Admin Current Survey Create',
                component: getView('OtherApplication/CurrentSurveyRequest.vue'),
                meta: {
                  auth: true,
                  title: '現況調査票',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Current Survey Detail',
                component: getView('OtherApplication/CurrentSurveyRequest.vue'),
                meta: {
                  auth: true,
                  title: '現況調査票申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Current Survey Edit',
                component: getView('OtherApplication/CurrentSurveyRequest.vue'),
                meta: {
                  auth: true,
                  title: '現況調査票申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Current Survey Approve',
                component: getView('OtherApplication/CurrentSurveyRequest.vue'),
                meta: {
                  auth: true,
                  title: '現況調査票申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'interview-shooting-request',
            name: 'Admin Interview Shooting',
            children: [
              {
                path: '',
                name: 'Admin Interview Shooting Create',
                component: getView(
                  'OtherApplication/InterviewShootingRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '取材撮影',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Interview Shooting Detail',
                component: getView(
                  'OtherApplication/InterviewShootingRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '取材撮影申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Interview Shooting Edit',
                component: getView(
                  'OtherApplication/InterviewShootingRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '取材撮影申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Interview Shooting Copy',
                component: getView(
                  'OtherApplication/InterviewShootingRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '取材撮影',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Interview Shooting Approve',
                component: getView(
                  'OtherApplication/InterviewShootingRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '取材撮影申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'building-photo-request',
            name: 'Admin Building Photo',
            children: [
              {
                path: '',
                name: 'Admin Building Photo Create',
                component: getView('OtherApplication/BuildingPhotoRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ロゴマーク、建物写真',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Building Photo Detail',
                component: getView('OtherApplication/BuildingPhotoRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ロゴマーク、建物写真-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Building Photo Copy',
                component: getView('OtherApplication/BuildingPhotoRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ロゴマーク、建物写真',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Building Photo Edit',
                component: getView('OtherApplication/BuildingPhotoRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ロゴマーク、建物写真',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Building Photo Approve',
                component: getView('OtherApplication/BuildingPhotoRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ロゴマーク、建物写真使用申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'advertisement-request',
            name: 'Admin Advertisement',
            children: [
              {
                path: '',
                name: 'Admin Advertisement Create',
                component: getView('OtherApplication/AdvertisementRequest.vue'),
                meta: {
                  auth: true,
                  title: '広告宣伝等承認',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Advertisement Detail ',
                component: getView('OtherApplication/AdvertisementRequest.vue'),
                meta: {
                  auth: true,
                  title: '広告宣伝等承認-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Advertisement Edit',
                component: getView('OtherApplication/AdvertisementRequest.vue'),
                meta: {
                  auth: true,
                  title: '広告宣伝等承認',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Advertisement Copy',
                component: getView('OtherApplication/AdvertisementRequest.vue'),
                meta: {
                  auth: true,
                  title: '広告宣伝等承認',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Advertisement Approve',
                component: getView('OtherApplication/AdvertisementRequest.vue'),
                meta: {
                  auth: true,
                  title: '広告宣伝等承認',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'building-staff-survey',
            name: 'Admin Building Staff Survey Form',
            children: [
              {
                path: '',
                name: 'Admin Building Staff Create',
                component: getView(
                  'OtherApplication/BuildingStaffSurveyRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '在館人員申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Building Staff Detail',
                component: getView(
                  'OtherApplication/BuildingStaffSurveyRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '在館人員申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Building Staff Copy',
                component: getView(
                  'OtherApplication/BuildingStaffSurveyRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '在館人員申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Building Staff Edit',
                component: getView(
                  'OtherApplication/BuildingStaffSurveyRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '在館人員申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Building Staff Approve',
                component: getView(
                  'OtherApplication/BuildingStaffSurveyRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: '在館人員申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'security-card-request',
            name: 'Admin Security Card',
            children: [
              {
                path: '',
                name: 'Admin Security Card Create',
                component: getView('OtherApplication/SecurityCardRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード発行依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Security Card Detail',
                component: getView('OtherApplication/SecurityCardRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード発行依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Security Card Edit',
                component: getView('OtherApplication/SecurityCardRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード発行依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Security Card Copy',
                component: getView('OtherApplication/SecurityCardRequest.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード発行',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Security Card Approve',
                component: getView('OtherApplication/SecurityCardRequest.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード発行依頼申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'change-security-card-request',
            name: 'Admin Change Security Card',
            children: [
              {
                path: '',
                name: 'Admin Change Security Card Create',
                component: getView(
                  'OtherApplication/ChangeSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード設定変更依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Change Security Card Detail',
                component: getView(
                  'OtherApplication/ChangeSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード設定変更依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Change Security Card Edit',
                component: getView(
                  'OtherApplication/ChangeSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード設定変更依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Change Security Card Copy',
                component: getView(
                  'OtherApplication/ChangeSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'セキュリティカード設定変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Change Security Card Approve',
                component: getView(
                  'OtherApplication/ChangeSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード設定変更依頼申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'change-security-card-reader',
            name: 'Admin Change Security Card Reader',
            children: [
              {
                path: '',
                name: 'Admin Change Security Card Reader Create',
                component: getView(
                  'OtherApplication/ChangeSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカードリーダー設定変更依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Change Security Card Reader Detail',
                component: getView(
                  'OtherApplication/ChangeSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカードリーダー設定変更依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Change Security Card Reader Edit',
                component: getView(
                  'OtherApplication/ChangeSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカードリーダー設定変更依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Change Security Card Reader Copy',
                component: getView(
                  'OtherApplication/ChangeSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: 'カードリーダー設定変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Change Security Card Reader Approve',
                component: getView(
                  'OtherApplication/ChangeSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカードリーダー設定変更依頼申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'remove-security-card-request',
            name: 'Admin Remove Security Card',
            children: [
              {
                path: '',
                name: 'Admin Remove Security Card Create',
                component: getView(
                  'OtherApplication/RemoveSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード抹消依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Remove Security Card Detail',
                component: getView(
                  'OtherApplication/RemoveSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード抹消依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Remove Security Card Edit',
                component: getView(
                  'OtherApplication/RemoveSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード抹消依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Remove Security Card Copy',
                component: getView(
                  'OtherApplication/RemoveSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'セキュリティカード抹消',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Remove Security Card Approve',
                component: getView(
                  'OtherApplication/RemoveSecurityCardRequest.vue'
                ),
                meta: {
                  auth: true,
                  title: 'ICカード抹消依頼申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'security-card-log-data-request',
            name: 'Admin Security Card Log Data',
            children: [
              {
                path: '',
                name: 'Admin Security Card Log Data Create',
                component: getView('OtherApplication/SecurityCardLogData.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード通行履歴ログデータ開示依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Security Card Log Data Detail',
                component: getView('OtherApplication/SecurityCardLogData.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード通行履歴ログデータ開示依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Security Card Log Data Edit',
                component: getView('OtherApplication/SecurityCardLogData.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード通行履歴ログデータ開示依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Security Card Log Data Copy',
                component: getView('OtherApplication/SecurityCardLogData.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード通行履歴ログ開示',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Security Card Log Data Approve',
                component: getView('OtherApplication/SecurityCardLogData.vue'),
                meta: {
                  auth: true,
                  title: 'ICカード通行履歴ログデータ開示依頼申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'disable-security-card',
            name: 'Admin DeActive Security Card',
            children: [
              {
                path: '',
                name: 'Admin DeActive Security Card Create',
                component: getView('OtherApplication/DeActiveSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin DeActive Security Card Detail',
                component: getView('OtherApplication/DeActiveSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin DeActive Security Card Edit',
                component: getView('OtherApplication/DeActiveSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin DeActive Security Card Copy',
                component: getView('OtherApplication/DeActiveSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin DeActive Security Card Approve',
                component: getView('OtherApplication/DeActiveSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'open-security-card/:requestId(\\d+)',
            name: 'Admin Open Security Card',
            children: [
              {
                path: '',
                name: 'Admin Open Security Card Create',
                component: getView('OtherApplication/OpenSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'detail',
                name: 'Admin Open Security Card Detail',
                component: getView('OtherApplication/OpenSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit',
                name: 'Admin Open Security Card Edit',
                component: getView('OtherApplication/OpenSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve',
                name: 'Admin Open Security Card Approve',
                component: getView('OtherApplication/OpenSecurityCard.vue'),
                meta: {
                  auth: true,
                  title: 'セキュリティカード失効・再開',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'change-work-time',
            name: 'Admin Change Work Time',
            children: [
              {
                path: '',
                name: 'Admin Change Work Time Create',
                component: getView('OtherApplication/ChangeWorkTime.vue'),
                meta: {
                  auth: true,
                  title: '臨時休業、営業時間変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Change Work Time Detail',
                component: getView('OtherApplication/ChangeWorkTime.vue'),
                meta: {
                  auth: true,
                  title: '臨時休業、営業時間変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Change Work Time Edit',
                component: getView('OtherApplication/ChangeWorkTime.vue'),
                meta: {
                  auth: true,
                  title: '臨時休業、営業時間変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Change Work Time Copy',
                component: getView('OtherApplication/ChangeWorkTime.vue'),
                meta: {
                  auth: true,
                  title: '臨時休業、営業時間変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Change Work Time Approve',
                component: getView('OtherApplication/ChangeWorkTime.vue'),
                meta: {
                  auth: true,
                  title: '臨時休業、営業時間変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'open-security-card-reader-log-data',
            name: 'Admin Open Security Card Reader',
            children: [
              {
                path: '',
                name: 'Admin Open Security Card Reader Create',
                component: getView(
                  'OtherApplication/OpenSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: 'カードリーダー通行履歴ログデータ開示依頼',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Open Security Card Reader Detail',
                component: getView(
                  'OtherApplication/OpenSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title:
                    'カードリーダー通行履歴ログデータ開示依頼申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Open Security Card Reader Edit',
                component: getView(
                  'OtherApplication/OpenSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title:
                    'カードリーダー通行履歴ログデータ開示依頼申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Open Security Card Reader Copy',
                component: getView(
                  'OtherApplication/OpenSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title: '臨時休業、営業時間変更',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Open Security Card Reader Approve',
                component: getView(
                  'OtherApplication/OpenSecurityCardReader.vue'
                ),
                meta: {
                  auth: true,
                  title:
                    'カードリーダー通行履歴ログデータ開示依頼申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'fire-equipment-request',
            name: 'Admin Fire Equipment',
            children: [
              {
                path: '',
                name: 'Admin Fire Equipment Create',
                component: getView('OtherApplication/FireEquipmentRequest.vue'),
                meta: {
                  auth: true,
                  title: '火気使用',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Fire Equipment Detail',
                component: getView('OtherApplication/FireEquipmentRequest.vue'),
                meta: {
                  auth: true,
                  title: '火気使用申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Fire Equipment Edit',
                component: getView('OtherApplication/FireEquipmentRequest.vue'),
                meta: {
                  auth: true,
                  title: '火気使用申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Fire Equipment Copy',
                component: getView('OtherApplication/FireEquipmentRequest.vue'),
                meta: {
                  auth: true,
                  title: '火気使用',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Fire Equipment Approve',
                component: getView('OtherApplication/FireEquipmentRequest.vue'),
                meta: {
                  auth: true,
                  title: '火気使用申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'area-sign-request',
            name: 'Admin Area Sign',
            children: [
              {
                path: '',
                name: 'Admin Area Sign Create',
                component: getView('OtherApplication/AreaSignRequest.vue'),
                meta: {
                  auth: true,
                  title: '共用部サイン工事',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Area Sign Detail',
                component: getView('OtherApplication/AreaSignRequest.vue'),
                meta: {
                  auth: true,
                  title: '共用部サイン工事申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Area Sign Edit',
                component: getView('OtherApplication/AreaSignRequest.vue'),
                meta: {
                  auth: true,
                  title: '共用部サイン工事申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Area Sign Copy',
                component: getView('OtherApplication/AreaSignRequest.vue'),
                meta: {
                  auth: true,
                  title: '共用部サイン工事',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Area Sign Approve',
                component: getView('OtherApplication/AreaSignRequest.vue'),
                meta: {
                  auth: true,
                  title: '共用部サイン工事申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'air-condition-request',
            name: 'Admin Air Condition',
            children: [
              {
                path: '',
                name: 'Admin Air Condition Create',
                component: getView('OtherApplication/AirConditionRequest.vue'),
                meta: {
                  auth: true,
                  title: '時間外空調',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Air Condition Detail',
                component: getView('OtherApplication/AirConditionRequest.vue'),
                meta: {
                  auth: true,
                  title: '時間外空調申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Air Condition Edit',
                component: getView('OtherApplication/AirConditionRequest.vue'),
                meta: {
                  auth: true,
                  title: '時間外空調申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Air Condition Copy',
                component: getView('OtherApplication/AirConditionRequest.vue'),
                meta: {
                  auth: true,
                  title: '時間外空調',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Air Condition Approve',
                component: getView('OtherApplication/AirConditionRequest.vue'),
                meta: {
                  auth: true,
                  title: '時間外空調申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'parking-ticket-request',
            name: 'Admin Parking Ticket',
            children: [
              {
                path: '',
                name: 'Admin Parking Ticket Create',
                component: getView('OtherApplication/PackingTicketRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車券',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Parking Ticket Detail',
                component: getView('OtherApplication/PackingTicketRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車券申請-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Parking Ticket Edit',
                component: getView('OtherApplication/PackingTicketRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車券申請-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Parking Ticket Copy',
                component: getView('OtherApplication/PackingTicketRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車券',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Parking Ticket Approve',
                component: getView('OtherApplication/PackingTicketRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車券申請-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'event-approval',
            name: 'Admin Event Approval',
            children: [
              {
                path: '',
                name: 'Admin Event Approval Create',
                component: getView('OtherApplication/EventApproval.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Event Approval Detail',
                component: getView('OtherApplication/EventApproval.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Event Approval Edit',
                component: getView('OtherApplication/EventApproval.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Event Approval Copy',
                component: getView('OtherApplication/EventApproval.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Event Approval Approve',
                component: getView('OtherApplication/EventApproval.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'important-notification',
            name: 'Admin Important Notification',
            children: [
              {
                path: '',
                name: 'Admin Important Notification Crete',
                component: getView(
                  'OtherApplication/ImportantNotification.vue'
                ),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Important Notification Edit',
                component: getView(
                  'OtherApplication/ImportantNotification.vue'
                ),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Important Notification Copy',
                component: getView(
                  'OtherApplication/ImportantNotification.vue'
                ),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Important Notification detail',
                component: getView(
                  'OtherApplication/ImportantNotification.vue'
                ),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'arrangement',
            name: 'Admin Arrangement',
            children: [
              {
                path: '',
                name: 'Admin Arrangement Create',
                component: getView('OtherApplication/ArrangementRequest.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Arrangement Detail',
                component: getView('OtherApplication/ArrangementRequest.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Arrangement Edit',
                component: getView('OtherApplication/ArrangementRequest.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Arrangement Copy',
                component: getView('OtherApplication/ArrangementRequest.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Arrangement Approve',
                component: getView('OtherApplication/ArrangementRequest.vue'),
                meta: {
                  auth: true,
                  title: '作業申請',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          },
          {
            path: 'safe-parking',
            name: 'Admin Safe Parking',
            children: [
              {
                path: '',
                name: 'Admin Safe Parking Create',
                component: getView('OtherApplication/SafeParkingRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車場確保',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: ':requestId(\\d+)',
                name: 'Admin Safe Parking Detail',
                component: getView('OtherApplication/SafeParkingRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車場確保-詳細画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'edit/:requestId(\\d+)',
                name: 'Admin Safe Parking Edit',
                component: getView('OtherApplication/SafeParkingRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車場確保-編集画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'copy/:requestId(\\d+)',
                name: 'Admin Safe Parking Copy',
                component: getView('OtherApplication/SafeParkingRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車場確保',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              },
              {
                path: 'approve/:requestId(\\d+)',
                name: 'Admin Safe Parking Approve',
                component: getView('OtherApplication/SafeParkingRequest.vue'),
                meta: {
                  auth: true,
                  title: '駐車場確保-承認画面',
                  fullWidth: true,
                  sidebar: SidebarType.HomePage
                }
              }
            ]
          }
        ]
      },
      {
        path: 'own-user-management',
        name: 'Admin Own user management',
        children: [
          {
            path: '',
            name: 'Admin Own user management List',
            component: getView('OwnUserManagement/OwnUserManagementList.vue'),
            meta: {
              auth: true,
              title: '自社ユーザー管理',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'create',
            name: 'Admin Own user management Create',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー登録',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'edit/:accountId(\\d+)',
            name: 'Admin Own user management Edit',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':accountId(\\d+)',
            name: 'Admin Own user management Detail',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'tenant-management',
        name: 'Admin Tenant management',
        children: [
          {
            path: '',
            name: 'Admin Tenant management List',
            component: getView('TenantManagement/TenantManagementList.vue'),
            meta: {
              auth: true,
              title: 'テナント一覧',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'create',
            name: 'Admin Tenant management create',
            component: getView('TenantManagement/TenantManagementForm.vue'),
            meta: {
              auth: true,
              title: 'テナント登録',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'edit/:id(\\d+)',
            name: 'Admin Tenant management edit',
            component: getView('TenantManagement/TenantManagementForm.vue'),
            meta: {
              auth: true,
              title: 'テナント情報-編集画面',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':id(\\d+)',
            name: 'Admin Tenant management detail',
            component: getView('TenantManagement/TenantManagementForm.vue'),
            meta: {
              auth: true,
              title: 'テナント情報',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'own-user-management/:accountId(\\d+)',
            name: 'Admin Own user management detail for Tenant management',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'own-user-management/edit/:accountId(\\d+)',
            name: 'Admin Own user management edit for Tenant management',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'contractor-company-designated',
        name: 'Admin Contractor Company Designated ',
        children: [
          {
            path: '',
            name: 'Admin Contractor Company Designated List',
            component: getView('ContractorCompany/ContractorCompanyList.vue'),
            meta: {
              auth: true,
              title: '施工会社一覧',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'register',
            name: 'Admin Contractor Company Designated create',
            component: getView('ContractorCompany/ContractorCompanyCreate.vue'),
            meta: {
              auth: true,
              title: '業者編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'edit/:contractorId(\\d+)',
            name: 'Admin Contractor Company Designated edit',
            component: getView('ContractorCompany/ContractorCompanyCreate.vue'),
            meta: {
              auth: true,
              title: '業者編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':contractorId(\\d+)',
            name: 'Admin Contractor Company Designated detail',
            component: getView('ContractorCompany/ContractorCompanyCreate.vue'),
            meta: {
              auth: true,
              title: '業者編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'own-user-management/:accountId(\\d+)',
            name: 'Admin Own user management detail for Contractor Company Designated',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'own-user-management/edit/:accountId(\\d+)',
            name: 'Admin Own user management edit for Contractor Company Designated',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'contractor-company-general',
        name: 'Admin Contractor Company',
        children: [
          {
            path: '',
            name: 'Admin Contractor Company List',
            component: getView('ContractorCompany/ContractorCompanyList.vue'),
            meta: {
              auth: true,
              title: '施工会社一覧',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'register',
            name: 'Admin Contractor Company create',
            component: getView('ContractorCompany/ContractorCompanyCreate.vue'),
            meta: {
              auth: true,
              title: '業者編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'edit/:contractorId(\\d+)',
            name: 'Admin Contractor Company edit',
            component: getView('ContractorCompany/ContractorCompanyCreate.vue'),
            meta: {
              auth: true,
              title: '業者編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':contractorId(\\d+)',
            name: 'Admin Contractor Company detail',
            component: getView('ContractorCompany/ContractorCompanyCreate.vue'),
            meta: {
              auth: true,
              title: '業者編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'own-user-management/:accountId(\\d+)',
            name: 'Admin Own user management detail for Contractor Company',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'own-user-management/edit/:accountId(\\d+)',
            name: 'Admin Own user management edit for Contractor Company',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'partner-management',
        name: 'Partner management',
        children: [
          {
            path: '',
            name: 'Partner management List',
            component: getView('PartnerManagement/PartnerManagementList.vue'),
            meta: {
              auth: true,
              title: 'パートナー管理',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'register',
            name: 'Partner management Register',
            component: getView(
              'PartnerManagement/PartnerManagementRegister.vue'
            ),
            meta: {
              auth: true,
              title: 'パートナー登録',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'edit/:partnerId(\\d+)',
            name: 'Partner management Edit',
            component: getView(
              'PartnerManagement/PartnerManagementRegister.vue'
            ),
            meta: {
              auth: true,
              title: 'パートナー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':partnerId(\\d+)',
            name: 'Partner management Detail',
            component: getView(
              'PartnerManagement/PartnerManagementRegister.vue'
            ),
            meta: {
              auth: true,
              title: 'パートナー登録',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'own-user-management/:accountId(\\d+)',
            name: 'Admin Own user management detail for Partner management',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'own-user-management/edit/:accountId(\\d+)',
            name: 'Admin Own user management edit for Partner management',
            component: getView('OwnUserManagement/OwnUserManagement.vue'),
            meta: {
              auth: true,
              title: 'メンバー編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'faq-management',
        name: 'Admin FAQ',
        children: [
          {
            path: '',
            name: 'Admin FAQ Management List',
            component: getView('faq/faqList.vue'),
            meta: {
              auth: true,
              title: 'よくあるご質問',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'create',
            name: 'Admin FAQ Management Create',
            component: getView('faq/faqCreate.vue'),
            meta: {
              auth: true,
              title: 'FAQ - 新規作成',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':faqId(\\d+)',
            name: 'Admin FAQ Management Detail',
            component: getView('faq/faqCreate.vue'),
            meta: {
              auth: true,
              title: 'FAQ - 編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'faq-public',
        name: 'FAQ',
        children: [
          {
            path: '',
            name: 'Admin FAQ Public List',
            component: getView('faq/faqListPublic.vue'),
            meta: {
              auth: true,
              title: 'よくあるご質問',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':faqId(\\d+)',
            name: 'Admin FAQ Public Detail',
            component: getView('faq/faqPublicDetail.vue'),
            meta: {
              auth: true,
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'download-form-request',
        name: 'Admin Download Form Request',
        children: [
          {
            path: '',
            name: 'Admin Download Form Request',
            component: getView('Download/DownloadFormRequest.vue'),
            meta: {
              auth: true,
              title: '申請書ダウンロード',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'download-form-general',
        name: 'Admin Download Form',
        children: [
          {
            path: '',
            name: 'Admin Download Form',
            component: getView('Download/DownloadForm.vue'),
            meta: {
              auth: true,
              title: '帳票ダウンロード',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'download-manually',
        name: 'Admin Download Manually',
        component: getView('Download/DownloadManually.vue'),
        meta: {
          auth: true,
          title: 'マニュアルダウンロード',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'qa-form',
        name: 'Admin QA Form',
        component: getView('QA/QAForm.vue'),
        meta: {
          auth: true,
          title: 'お問い合わせ',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'general-management',
        name: 'Admin General Management',
        children: [
          {
            path: '',
            name: 'Admin General Management List',
            component: getView('Notifications/NotificationList.vue'),
            meta: {
              auth: true,
              title: '全館案内一覧',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'create',
            name: 'Admin General Management Create',
            component: getView('Notifications/NotificationAction.vue'),
            meta: {
              auth: true,
              title: '全館案内 - 新規作成',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':notificationId(\\d+)',
            name: 'Admin General Management Detail',
            component: getView('Notifications/NotificationAction.vue'),
            meta: {
              auth: true,
              title: '全館案内 - 編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'news-management',
        name: 'Admin News Management',
        children: [
          {
            path: '',
            name: 'Admin News Management List',
            component: getView('Notifications/NotificationList.vue'),
            meta: {
              auth: true,
              title: 'ニュース一覧',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'create',
            name: 'Admin News Management Create',
            component: getView('Notifications/NotificationAction.vue'),
            meta: {
              auth: true,
              title: 'ニュース - 新規作成',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':notificationId(\\d+)',
            name: 'Admin News Management Detail',
            component: getView('Notifications/NotificationAction.vue'),
            meta: {
              auth: true,
              title: 'ニュース - 編集',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'news',
        name: 'Admin News',
        children: [
          {
            path: '',
            name: 'Admin New List',
            component: getView('Notifications/NotificationPublicList.vue'),
            meta: {
              auth: true,
              title: 'お知らせ一覧',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':notificationId(\\d+)',
            name: 'Admin New Detail',
            component: getView('Notifications/NotificationDetail.vue'),
            meta: {
              auth: true,
              title: '館内〇〇のお知らせ',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'general-information',
        name: 'Admin General Information',
        children: [
          {
            path: '',
            name: 'Admin General Information List',
            component: getView('Notifications/NotificationPublicList.vue'),
            meta: {
              auth: true,
              title: 'お知らせ一覧',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':notificationId(\\d+)',
            name: 'Admin General Information Detail',
            component: getView('Notifications/NotificationDetail.vue'),
            meta: {
              auth: true,
              title: '館内〇〇のお知らせ',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'mail-delivery',
        name: 'Mail delivery',
        component: getView('MailDelivery/MailDeliveryList.vue'),
        meta: {
          auth: true,
          title: '自動送信メール編集',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'mail-history',
        name: 'Mail history',
        children: [
          {
            path: '',
            name: 'List mail history',
            component: getView('MailDelivery/ListMailHistory.vue'),
            meta: {
              auth: true,
              title: 'メール送信予定、履歴一覧',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'create',
            name: 'Send manual mail Create',
            component: getView('MailDelivery/SendManualMail.vue'),
            meta: {
              auth: true,
              title: 'メール送信',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: ':id(\\d+)',
            name: 'Send manual mail detail',
            component: getView('MailDelivery/SendManualMail.vue'),
            meta: {
              auth: true,
              title: 'メール配信',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          },
          {
            path: 'edit/:id(\\d+)',
            name: 'Send manual mail edit',
            component: getView('MailDelivery/SendManualMail.vue'),
            meta: {
              auth: true,
              title: 'メール配信',
              fullWidth: true,
              sidebar: SidebarType.HomePage
            }
          }
        ]
      },
      {
        path: 'my-account',
        name: 'Admin My Account',
        component: getView('home/MyAccount.vue'),
        meta: {
          auth: true,
          title: 'マイアカウント',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'parking-lot-holiday-settings',
        name: 'Admin Parking Lot Holiday Settings',
        component: getView('ParkingLotHoliday/HolidayList.vue'),
        meta: {
          auth: true,
          title: '駐車場休日設定',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'list-request-form',
        name: 'List Request Form',
        component: getView('Request/ListRequestForm.vue'),
        meta: {
          auth: true,
          title: '',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'aggregation',
        name: 'Aggregation',
        component: getView('Aggregation/AggregationForm.vue'),
        meta: {
          auth: true,
          title: '集計',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'visit-reservation-tenant',
        name: 'Visit Reservation Tenant',
        component: getView('AdmissionManagement/VisitReservationTenant.vue'),
        meta: {
          auth: true,
          title: '来訪予約（テナント）',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'card-information-list',
        name: 'Card Information List',
        component: getView('AdmissionManagement/CardInfomationList.vue'),
        meta: {
          auth: true,
          title: 'カード情報一覧',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'card-log',
        name: 'Card Log',
        component: getView('AdmissionManagement/CardLog.vue'),
        meta: {
          auth: true,
          title: '',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'list-reception',
        name: 'List Reception',
        component: getView('AdmissionManagement/ListReception.vue'),
        meta: {
          auth: true,
          title: '入館受付',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'aggregation',
        name: 'Aggregation',
        component: getView('Aggregation/AggregationForm.vue'),
        meta: {
          auth: true,
          title: '集計',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'admission-information-list',
        name: 'Admission Information List',
        component: getView('AdmissionManagement/AdmissionInformationList.vue'),
        meta: {
          auth: true,
          title: '入館情報検索',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'change-card-status-bulk',
        name: 'Change card status bulk',
        component: getView('AdmissionManagement/ChangeCardStatusBulk.vue'),
        meta: {
          auth: true,
          title: '',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'checking-tightening-process',
        name: 'Checking tightening process',
        component: getView('AdmissionManagement/CheckingTighteningProcess.vue'),
        meta: {
          auth: true,
          title: '',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      },
      {
        path: 'admission-details/:id',
        name: 'Admission details',
        component: getView('AdmissionManagement/AdmissionDetails.vue'),
        meta: {
          auth: true,
          title: '入館情報詳細',
          fullWidth: true,
          sidebar: SidebarType.HomePage
        }
      }
    ]
  },
  // Partner router

  {
    path: '/components',
    component: getView('ComponentsView.vue')
  },
  {
    path: '/sitemap',
    component: () => import('../views/SiteMap.vue')
  },
  {
    path: '/404',
    name: '404 page',
    component: getView('Page404View.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/403',
    name: '403 page',
    component: getView('Page403View.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/system-error',
    name: 'system error',
    component: getView('SystemErrorView.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404 Not Found',
    component: getView('Page404View.vue'),
    meta: {
      auth: false
    }
  }
]
