import { createI18n } from 'vue-i18n'

import EN from '@/locales/en.json'
import JA from '@/locales/ja.json'

// Type-define 'en-US' as the master schema for the resource
type MessageSchema = typeof JA

export const i18n = createI18n<[MessageSchema], 'en' | 'ja'>({
  locale: 'ja',
  messages: {
    ja: JA,
    en: EN
  },
  fallbackLocale: 'en'
})
