import { createRouter, createWebHistory } from 'vue-router'

import middlewareAuth from '@/middleware/auth'
import { SITE_APP } from '@/utils/constant'

import { routes } from './routes'
import { routesAdmin } from './routesAdmin'

const router = createRouter({
  history: createWebHistory(),
  routes: process.env.VUE_APP_SITE === SITE_APP.ADMIN ? routesAdmin : routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta?.title ?? process.env.VUE_APP_NAME ?? ''
  middlewareAuth(to, from, next)
})

export default router
